const state = {
	requirement: {},
	steps: [],
	publication: null, //is object
	curriculum_filter: null, //is objetct
	evaluation: null, //is objetct
	previous_interview: null, //is objetct
	principal_interview: null, //is objetct
	medical_examen: null, //is objetct
	closing: null, //is objetct
	finished: null,
	isEditable: null,
};

export default state;
