<template>
	<div class="d-block d-md-inline">
		<a class="btn btn-xs btn-link btn-icon bigger-130 text-danger" @click="mtd_emitActionRemove" title="Eliminar">
			<feather type="trash"></feather>
		</a>
	</div>
</template>

<script>
export default {
	props: ['propRow'],
	methods: {
		mtd_emitActionRemove() {
			this.$swal
				.fire({
					title: '¿Esta seguro de eliminar este recurso?',
					text: `${this.propRow.name ? this.propRow.name : this.propRow.description ? this.propRow.description : ''}`,
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#e74c3c',
					confirmButtonText: 'Si, eliminar',
				})
				.then(result => {
					if (result.isConfirmed) {
						this.$emit('action-remove', this.propRow);
					}
				});
		},
	},
};
</script>
