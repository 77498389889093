import {
	BENEFIT_TYPES_INDEX__SET_ALL,
	BENEFIT_TYPES_INDEX__ADD_RESOURCE,
	BENEFIT_TYPES_INDEX__UPDATE_RESOURCE,
	BENEFIT_TYPES_INDEX__REMOVE_RESOURCE,
	BENEFIT_TYPES_INDEX__CLEAR_ALL,
} from './types';

export default {
	[BENEFIT_TYPES_INDEX__SET_ALL]: (state, payload) => {
		state.benefit_types_index = payload;
	},
	[BENEFIT_TYPES_INDEX__ADD_RESOURCE]: (state, payload) => {
		state.benefit_types_index.data.push(payload.benefit_type);
	},
	[BENEFIT_TYPES_INDEX__UPDATE_RESOURCE]: (state, { benefit_type }) => {
		state.benefit_types_index.data = state.benefit_types_index.data.map(el => {
			if (el.id == benefit_type.id) el = benefit_type;
			return el;
		});
	},
	[BENEFIT_TYPES_INDEX__REMOVE_RESOURCE]: (state, { benefit_type }) => {
		state.benefit_types_index.data = state.benefit_types_index.data.filter(el => el.id != benefit_type.id);
	},
	[BENEFIT_TYPES_INDEX__CLEAR_ALL]: state => {
		state.benefit_types_index = { ...{ data: [], links: {}, meta: {} } };
	},
};
