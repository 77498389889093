export default function (Vue) {
	Vue.httpCode = {
		accordingStatus(response) {
			if (response.status == 400) {
				if (response.data.message) window.alertify.alert('<i class="fas fa-bomb"></i> Error 400', response.data.message);
			}

			if (response.status == 401) {
				if (response.data.message == 'Unauthenticated.') {
					window.alertify.alert('<i class="fas fa-bomb"></i> Error 401', 'Sesión expirada.', () => {
						window.location.reload();
					});
				} else {
					window.simplealert('error', response.data.message, '<i class="fas fa-bomb"></i> Error 401');
				}
			}

			if (response.status == 403) {
				window.location.href = '/app#/';
				if (response.data.message) window.alertify.alert('<i class="fas fa-bomb"></i> Error 403', response.data.message);
			}

			if (response.status == 404) {
				if (response.data.message) window.alertify.alert('<i class="fas fa-bomb"></i> Error 404', response.data.message);
				else window.simplealert('error', 'El endpoint no existe.', '<i class="fas fa-bomb"></i> Error 404');
			}

			if (response.status == 409) {
				if (response.data.message) window.alertify.alert('<i class="fas fa-bomb"></i> Error 409', response.data.message);
			}

			if (response.status == 500) {
				if (response.data.message) {
					if (response.data.message.indexOf('Integrity constraint violation') == -1) window.alertify.alert('<i class="fas fa-bomb"></i> Error 500', response.data.message);
					else window.alertify.alert('<i class="fas fa-bomb"></i> Error 500', 'Al parecer el registro que deseas eliminar está siendo usado.');
				} else window.alertify.alert('<i class="fas fa-bomb"></i> Error 500', 'No se logró procesar la petición.');
			}
		},
	};

	Object.defineProperties(Vue.prototype, {
		$redirect: {
			get: () => {
				return Vue.httpCode;
			},
		},
	});
}
