import {
	POSTULANTS_INDEX__SET_ALL,
	POSTULANTS_INDEX__ADD_RESOURCE,
	POSTULANTS_INDEX__UPDATE_RESOURCE,
	POSTULANTS_INDEX__REMOVE_RESOURCE,
	POSTULANTS_INDEX__CLEAR_ALL,
} from './types';

export default {
	[POSTULANTS_INDEX__SET_ALL]: (state, payload) => {
		state.postulants_index = payload;
	},
	[POSTULANTS_INDEX__ADD_RESOURCE]: (state, payload) => {
		state.postulants_index.data.push(payload.postulant);
	},
	[POSTULANTS_INDEX__UPDATE_RESOURCE]: (state, { postulant }) => {
		state.postulants_index.data = state.postulants_indexx.data.map(el => {
			if (el.id == postulant.id) el = postulant;
			return el;
		});
	},
	[POSTULANTS_INDEX__REMOVE_RESOURCE]: (state, { postulant }) => {
		state.postulants_index.data = state.postulants_index.data.filter(el => el.id != postulant.id);
	},
	[POSTULANTS_INDEX__CLEAR_ALL]: state => {
		state.postulants_index = { ...{ data: [], links: {}, meta: {} } };
	},
};
