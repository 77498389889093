import { SOURCES_INDEX__SET_ALL, SOURCES_INDEX__ADD_RESOURCE, SOURCES_INDEX__UPDATE_RESOURCE, SOURCES_INDEX__REMOVE_RESOURCE, SOURCES_INDEX__CLEAR_ALL } from './types';

export default {
	[SOURCES_INDEX__SET_ALL]: (state, payload) => {
		state.sources_index = payload;
	},
	[SOURCES_INDEX__ADD_RESOURCE]: (state, payload) => {
		state.sources_index.data.push(payload.source);
	},
	[SOURCES_INDEX__UPDATE_RESOURCE]: (state, { source }) => {
		state.sources_index.data = state.sources_index.data.map(el => {
			if (el.id == source.id) el = source;
			return el;
		});
	},
	[SOURCES_INDEX__REMOVE_RESOURCE]: (state, { source }) => {
		state.sources_index.data = state.sources_index.data.filter(el => el.id != source.id);
	},
	[SOURCES_INDEX__CLEAR_ALL]: state => {
		state.sources_index = { ...{ data: [], links: {}, meta: {} } };
	},
};
