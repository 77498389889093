import api from '../api';

/**********************************************************************
 * *Actions for crud's
 **********************************************************************/

export const get = ({ commit }, request) => {
	return new Promise((resolve, reject) => {
		window.eventBus.$emit('loading', true);
		api.get(
			request,
			response => {
				if (request.mutation) commit(request.mutation, response.data);
				resolve(response.data);
				window.eventBus.$emit('loading', false);
			},
			error => {
				mtd_showErrors(error);
				reject(error);
				window.eventBus.$emit('loading', false);
			}
		);
	});
};

export const get_sin_bus = ({ commit }, request) => {
	return new Promise((resolve, reject) => {
		api.get(
			request,
			response => {
				if (request.mutation) commit(request.mutation, response.data);
				resolve(response.data);
			},
			error => {
				reject(error);
			}
		);
	});
};

export const post = ({ commit }, request) => {
	if (window.eventBus) window.eventBus.$emit('loading', true);
	return new Promise((resolve, reject) => {
		api.post(
			request,
			response => {
				if (request.mutation) commit(request.mutation, response.data);
				resolve(response.data);
				if (window.eventBus) window.eventBus.$emit('loading', false);
			},
			error => {
				mtd_showErrors(error);
				reject(error.data.errors);
				if (window.eventBus) window.eventBus.$emit('loading', false);
			}
		);
	});
};

export const destroy = ({ commit }, request) => {
	window.eventBus.$emit('loading', true);
	return new Promise((resolve, reject) => {
		api.destroy(
			request,
			response => {
				if (request.mutation) commit(request.mutation, response.data);
				resolve(response.data);
				window.eventBus.$emit('loading', false);
			},
			error => {
				mtd_showErrors(error);
				reject(error);
				window.eventBus.$emit('loading', false);
			}
		);
	});
};

export const update = ({ commit }, request) => {
	window.eventBus.$emit('loading', true);
	return new Promise((resolve, reject) => {
		api.update(
			request,
			response => {
				if (request.mutation) commit(request.mutation, response.data);
				resolve(response.data);
				window.eventBus.$emit('loading', false);
			},
			error => {
				mtd_showErrors(error);
				reject(error.data.errors);
				window.eventBus.$emit('loading', false);
			}
		);
	});
};

export const postWithFile = ({ commit }, request) => {
	window.eventBus.$emit('loading', true);
	return new Promise((resolve, reject) => {
		api.postWithFile(
			request,
			response => {
				if (request.mutation) commit(request.mutation);
				resolve(response.data);
				window.eventBus.$emit('loading', false);
			},
			error => {
				reject(error.data.errors);
				window.eventBus.$emit('loading', false);
			}
		);
	});
};

export const execute_commit = ({ commit }, request) => {
	if (request.payload) commit(request.mutation, request.payload);
	else commit(request.mutation);
};

const mtd_showErrors = err => {
	if (err.data != undefined) {
		const errors = err.data.errors;
		for (const key in errors) {
			const element = errors[key][0];
			window.message('error', element, 'Atención', { timeout: 6000 });
		}
	} else {
		for (const key in err) {
			const element = err[key][0];
			window.message('error', element, 'Atención', { timeout: 6000 });
		}
	}
};
