import {
	REQUIREMENTS_INDEX__SET_ALL,
	REQUIREMENTS_INDEX__ADD_RESOURCE,
	REQUIREMENTS_INDEX__UPDATE_RESOURCE,
	REQUIREMENTS_INDEX__REMOVE_RESOURCE,
	REQUIREMENTS_INDEX__CLEAR_ALL,
} from './types';

export default {
	[REQUIREMENTS_INDEX__SET_ALL]: (state, payload) => {
		state.requirements_index = payload;
	},
	[REQUIREMENTS_INDEX__ADD_RESOURCE]: (state, payload) => {
		state.requirements_index.data.push(payload.requirement);
	},
	[REQUIREMENTS_INDEX__UPDATE_RESOURCE]: (state, { requirement }) => {
		state.requirements_index.data = state.requirements_index.data.map(el => {
			if (el.id == requirement.id) el = requirement;
			return el;
		});
	},
	[REQUIREMENTS_INDEX__REMOVE_RESOURCE]: (state, { requirement }) => {
		state.requirements_index.data = state.requirements_index.data.filter(el => el.id != requirement.id);
	},
	[REQUIREMENTS_INDEX__CLEAR_ALL]: state => {
		state.requirements_index = { ...{ data: [], links: {}, meta: {} } };
	},
};
