import { CAMPUS_INDEX__SET_ALL, CAMPUS_INDEX__ADD_RESOURCE, CAMPUS_INDEX__UPDATE_RESOURCE, CAMPUS_INDEX__REMOVE_RESOURCE, CAMPUS_INDEX__CLEAR_ALL } from './types';

export default {
	[CAMPUS_INDEX__SET_ALL]: (state, payload) => {
		state.campus_index = payload;
	},
	[CAMPUS_INDEX__ADD_RESOURCE]: (state, payload) => {
		state.campus_index.data.push(payload.campu);
	},
	[CAMPUS_INDEX__UPDATE_RESOURCE]: (state, { campu }) => {
		state.campus_index.data = state.campus_index.data.map(el => {
			if (el.id == campu.id) el = campu;
			return el;
		});
	},
	[CAMPUS_INDEX__REMOVE_RESOURCE]: (state, { campu }) => {
		state.campus_index.data = state.campus_index.data.filter(el => el.id != campu.id);
	},
	[CAMPUS_INDEX__CLEAR_ALL]: state => {
		state.campus_index = { ...{ data: [], links: {}, meta: {} } };
	},
};
