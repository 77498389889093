require('./bootstrap');
/**
 * !IMPORT
 */
window.Vue = require('vue');
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueBus from 'vue-bus';
import HttpCode from './packages/http_code'; // Http:status code
import Snotify, { SnotifyPosition } from 'vue-snotify'; // Import Snotify
import VeeValidate, { Validator } from 'vee-validate';
import es from 'vee-validate/dist/locale/es'; // Vee-validate: config - spanish
import VueSimpleAlert from 'vue-simple-alert';
import '@fortawesome/fontawesome-free/css/all.css';
import './sass/app.scss';
import VueFeather from 'vue-feather';
import vueDirectiveTooltip from 'vue-directive-tooltip';
import Can from './packages/can'; // Package for permissions for role
const moment = require('moment');
require('moment/locale/es');

/**
 * VueFlatPickr
 */
import 'flatpickr/dist/flatpickr.css';

import flatpickr from 'flatpickr';
import { Spanish } from 'flatpickr/dist/l10n/es.js';
flatpickr.localize(Spanish);

flatpickr.setDefaults({
	dateFormat: 'Y-m-d',
	altInput: true,
	altFormat: 'j F, Y',
});

import VueFlatPickr from 'vue-flatpickr-component';
Vue.use(VueFlatPickr);

/**
 *  Vue-Select
 */
import vSelect from 'vue-select';
/**
 * animate.css
 */
import 'animate.css';
/**
 * VueSweetalert2
 */
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2, {
	confirmButtonColor: '#28a745',
	showCancelButton: true,
	cancelButtonText: 'Cancelar',
});
/**
 * IMask Plugin
 */
import { IMaskComponent } from 'vue-imask';

/**
 * !VUE:
 * Use of extensions or components external
 */
Vue.use(Can);
Vue.use(VueFeather);
Vue.use(VueBus);
Vue.use(HttpCode);
Vue.use(Snotify, {
	toast: {
		position: SnotifyPosition.rightTop,
		timeout: 3500,
	},
});
Vue.use(require('vue-moment'), {
	moment,
});
Vue.use(VeeValidate, {
	aria: true,
});
Validator.localize('es', es);
Vue.use(VueSimpleAlert);
Vue.use(vueDirectiveTooltip, {
	delay: 100,
	placement: 'left',
	triggers: ['hover'],
	offset: 0,
});
Vue.config.productionTip = false;

/**
 * !GLOBAL COMPONENTS
 */
Vue.component('page-header', require('./components/shared/page/Header.vue').default);
Vue.component('page-body', require('./components/shared/page/Body.vue').default);
Vue.component('modal', require('./components/shared/modal/Modal.vue').default);
Vue.component('ErrorBackendComponent', require('./components/shared/ErrorBackendComponent.vue').default);
Vue.component('v-select', vSelect);
Vue.component('IMaskComponent', IMaskComponent);

window.message = (type, content, title = null, config) => {
	window.Snotify[type](content, title, config);
};

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app');
