import { USERS_INDEX__SET_ALL, USERS_INDEX__ADD_RESOURCE, USERS_INDEX__UPDATE_RESOURCE, USERS_INDEX__REMOVE_RESOURCE, USERS_INDEX__CLEAR_ALL } from './types';

export default {
	[USERS_INDEX__SET_ALL]: (state, payload) => {
		state.users_index = payload;
	},
	[USERS_INDEX__ADD_RESOURCE]: (state, { user }) => {
		state.users_index.data.push(user);
	},
	[USERS_INDEX__UPDATE_RESOURCE]: (state, { user }) => {
		state.users_index.data = state.users_index.data.map(el => {
			if (el.id == user.id) el = user;
			return el;
		});
	},
	[USERS_INDEX__REMOVE_RESOURCE]: (state, { user }) => {
		state.users_index.data = state.users_index.data.filter(el => el.id != user.id);
	},
	[USERS_INDEX__CLEAR_ALL]: state => {
		state.users_index = {
			...{
				data: [],
				links: {},
				meta: {},
			},
		};
	},
};
