<template>
	<div class="d-block d-md-inline">
		<a @click="mtd_emitActionEdit" title="Editar" class="btn btn-xs btn-link btn-icon bigger-130 text-success">
			<feather type="edit"></feather>
		</a>
	</div>
</template>

<script>
export default {
	name: 'c-button-edit',
	props: ['propRow'],
	methods: {
		mtd_emitActionEdit: function () {
			this.$emit('action-edit', this.propRow);
		},
	},
};
</script>
