/**
 * !simple-alert: Config
 */
window.simplealert = (type, content, title = null) => {
	window.Vue.default.alert(content, title, type);
};
// window.$.fn.modal.Constructor.prototype.enforceFocus = function () {};

/**
 * !ALERTIFY: Config
 */
window.alertify = require('alertifyjs');
window.alertify.defaults.glossary.title = 'KM CAPITAL';
window.alertify.defaults.theme.ok = 'btn btn-primary';
window.alertify.defaults.theme.cancel = 'btn btn-default';
window.alertify.defaults.theme.input = 'form-control';
