<template>
	<router-view></router-view>
</template>

<script>
//Rutas
// import routes from '@/routes/index';

import { mapGetters } from 'vuex';
export default {
	name: 'app',
	computed: {
		...mapGetters(['authenticated_user']),
	},
	created() {
		// if (localStorage.getItem('token')) {
		// 	this.mtd_setRoutesOfRole();
		// }
	},
	methods: {
		// mtd_setRoutesOfRole() {
		// 	const rol = this.authenticated_user.roles[0];
		// 	routes[rol.code].forEach(route => {
		// 		if (!this.$router.getRoutes().find(el => el.name == route.name)) {
		// 			this.$router.addRoute(route);
		// 		}
		// 	});
		// 	console.log('run App.vue');
		// 	console.log(this.$router.getRoutes());
		// },
	},
};
</script>
