import Vue from 'vue';
import VueRouter from 'vue-router';

//Rutas
import routes from '@/routes/index';

Vue.use(VueRouter);

const router = new VueRouter({
	mode: 'history',
	routes: routes,
});

router.beforeEach((to, from, next) => {
	if (to.matched.some(record => record.meta.restricted)) {
		if (Vue.can.permission(to.meta)) {
			next();
		} else {
			next({ path: '/' });
			window.message('error', 'No intentes acceder a sitios restringidos para tu rol');
		}
	} else next();
});

export default router;
