<script>
export default {
	props: {
		meta: {
			type: Object,
			default: () => {},
		},
		links: {
			type: Object,
			default: () => {},
		},
	},
	computed: {
		isMetaResource() {
			return !!this.meta;
		},
		isLinksResource() {
			return !!this.links;
		},
		currentPage() {
			return this.meta.current_page;
		},
		lastPage() {
			return this.meta.last_page;
		},
		nextPageUrl() {
			return this.links.next;
		},
		prevPageUrl() {
			return this.links.prev;
		},
		pageRange() {
			var current = this.currentPage;
			var last = this.lastPage;
			//var second_last = last - 1;
			var range = [];

			if (last <= 5) {
				for (var counter = 1; counter <= last; counter++) {
					range.push(counter);
				}
			} else if (last > 5) {
				if (current <= 4) {
					// eslint-disable-next-line no-redeclare
					for (var counter = 1; counter < 6; counter++) {
						range.push(counter);
					}
					range.push('...');
					range.push(last);
				} else if (current > 4 && current < last - 3) {
					range.push(1);
					range.push('...');
					// eslint-disable-next-line no-redeclare
					for (var counter = current - 1; counter <= current + 1; counter++) {
						range.push(counter);
					}
					range.push('...');
					range.push(last);
				} else {
					range.push(1);
					range.push('...');
					// eslint-disable-next-line no-redeclare
					for (var counter = last - 4; counter <= last; counter++) {
						range.push(counter);
					}
				}
			}
			return range;
		},
	},
	methods: {
		previousPage() {
			this.selectPage(this.currentPage - 1);
		},
		nextPage() {
			this.selectPage(this.currentPage + 1);
		},
		selectPage(page) {
			if (page === '...') {
				return;
			}
			this.$emit('pagination-change-page', page);
		},
	},
	render() {
		return this.$scopedSlots.default({
			meta: this.meta,
			links: this.links,
			computed: {
				isMetaResource: this.isMetaResource,
				isLinksResource: this.isLinksResource,
				currentPage: this.currentPage,
				lastPage: this.lastPage,
				nextPageUrl: this.nextPageUrl,
				prevPageUrl: this.prevPageUrl,
				pageRange: this.pageRange,
			},
			prevButtonEvents: {
				click: e => {
					e.preventDefault();
					this.previousPage();
				},
			},
			nextButtonEvents: {
				click: e => {
					e.preventDefault();
					this.nextPage();
				},
			},
			pageButtonEvents: page => ({
				click: e => {
					e.preventDefault();
					this.selectPage(page);
				},
			}),
		});
	},
};
</script>
