export default {
	requirement: ({ requirement }) => {
		return requirement;
	},
	steps: ({ steps }) => {
		return steps;
	},
	publication: ({ publication }) => {
		return publication;
	},
	curriculum_filter: ({ curriculum_filter }) => {
		return curriculum_filter;
	},
	evaluation: ({ evaluation }) => {
		return evaluation;
	},
	previous_interview: ({ previous_interview }) => {
		return previous_interview;
	},
	principal_interview: ({ principal_interview }) => {
		return principal_interview;
	},
	medical_examen: ({ medical_examen }) => {
		return medical_examen;
	},
	closing: ({ closing }) => {
		return closing;
	},
	finished: ({ finished }) => {
		return finished;
	},
	isEditable: ({ isEditable }) => {
		return isEditable;
	},
};
