const routes = [
	{
		path: '/',
		component: () => import('../layouts/LoginLayout.vue' /* webpackChunkName: "login-layout" */),
		children: [
			{
				path: '',
				component: () => import('@/pages/login/Login.vue' /* webpackChunkName: "login" */),
			},
		],
	},
	{
		path: '/app',
		name: 'app',
		component: () => import('../layouts/Main' /* webpackChunkName: "Main" */),
		meta: {},
		children: [
			{
				path: '/home',
				name: 'home',
				component: () => import('../pages/home' /* webpackChunkName: "home" */),
				meta: {
					restricted: false,
				},
			},
			// MANTENIMIENTOS
			{
				path: '/selection_administrator/enterprises',
				name: 'selection_administrator-enterprises',
				component: () => import('@/pages/selection_administrator/enterprises/Enterprise' /* webpackChunkName: "selection_administrator-Enterprise" */),
				meta: {
					restricted: true,
					permission: 'maintenances-index',
				},
			},
			{
				path: '/selection_administrator/campus',
				name: 'selection_administrator-campus',
				component: () => import('@/pages/selection_administrator/campus/Campu' /* webpackChunkName: "selection_administrator-Campu" */),
				meta: {
					restricted: true,
					permission: 'maintenances-index',
				},
			},
			{
				path: '/selection_administrator/job_positions',
				name: 'selection_administrator-job_positions',
				component: () => import('@/pages/selection_administrator/job_positions/JobPositions' /* webpackChunkName: "selection_administrator-job_positions" */),
				meta: {
					restricted: true,
					permission: 'maintenances-index',
				},
			},
			{
				path: '/selection_administrator/benefit_types',
				name: 'selection_administrator-benefit-types',
				component: () => import('@/pages/selection_administrator/benefit_types/BenefitTypes' /* webpackChunkName: "selection_administrator-BenefitTypes" */),
				meta: {
					restricted: true,
					permission: 'maintenances-index',
				},
			},
			{
				path: '/selection_administrator/sources',
				name: 'selection_administrator-sources',
				component: () => import('@/pages/selection_administrator/sources/Sources' /* webpackChunkName: "selection_administrator-Sources" */),
				meta: {
					restricted: true,
					permission: 'maintenances-index',
				},
			},
			{
				path: '/selection_administrator/users',
				name: 'selection_administrator-users',
				component: () => import('@/pages/selection_administrator/users/Users' /* webpackChunkName: "selection_administrator-users" */),
				meta: {
					restricted: true,
					permission: 'maintenances-index',
				},
			},
			// REQUERIMIENTOS
			// REQUERIMIENTOS - CREAR (RESPONSABLE DE REQUERIMIENTOS)
			{
				path: '/requirement_responsable/requirements/create',
				name: 'requirement_responsable-requirements-create',
				component: () => import('@/pages/requirement_responsable/requirements/create/Create.vue' /* webpackChunkName: "requirement_responsable-requirements-create" */),
				meta: {
					restricted: true,
					permission: 'home-index',
				},
			},
			// REQUERIMIENTOS - INDEXAR (RESPONSABLE DE REQUERIMIENTOS)
			{
				path: '/requirement_responsable/requirements/index',
				name: 'requirement_responsable-requirements-index',
				component: () => import('@/pages/requirement_responsable/requirements/index/Index.vue' /* webpackChunkName: "requirement_responsable-requirements-index" */),
				meta: {
					restricted: true,
					permission: 'home-index',
				},
			},
			// REQUERIMIENTOS - INDEXAR (ADMINISTRADOR DE SELECCION)
			{
				path: '/selection_administrator/requirements/index',
				name: 'selection_administrator-requirements-index',
				component: () => import('@/pages/selection_administrator/requirements/index/Index.vue' /* webpackChunkName: "selection_administrator-requirements-index" */),
				meta: {
					restricted: true,
					permission: 'home-index',
				},
			},
			// REQUERIMIENTO - ESTADO
			{
				path: '/requirement/:requirement_number',
				name: 'requirement',
				component: () => import('@/pages/shared/requirements/state/State.vue' /* webpackChunkName: "requirements-state" */),
				meta: {
					restricted: true,
					permission: 'home-index',
				},
				props: true,
			},
			// MIS POSTULACIONES (POSTULANTE)
			{
				path: '/postulant/requirements/index',
				name: 'postulant-requirements-index',
				component: () => import('@/pages/postulant/requirements/index/Index.vue' /* webpackChunkName: "postulant-requirements-index" */),
				meta: {
					restricted: true,
					permission: 'home-index',
				},
			},
			// DESARROLLO DE EVALUACIONES (POSTULANTES)
			{
				path: '/postulant/evaluation/:evaluation_id/development',
				name: 'postulant-evaluation-development',
				component: () => import('@/pages/postulant/evaluation/Development.vue' /* webpackChunkName: "postulant-evaluation-development" */),
				meta: {
					restricted: true,
					permission: 'home-index',
				},
				props: true,
			},
			// REQUERIMIENTOS - CREAR (ADMINISTRADOR)
			{
				path: '/administrator/requirements/create',
				name: 'administrator-requirements-create',
				component: () => import('@/pages/administrator/requirements/create/Create.vue' /* webpackChunkName: "administrator-requirements-create" */),
				meta: {
					restricted: true,
					permission: 'home-index',
				},
			},
			// REQUERIMIENTOS - INDEXAR (ADMINISTRADOR)
			{
				path: '/administrator/requirements/index',
				name: 'administrator-requirements-index',
				component: () => import('@/pages/administrator/requirements/index/Index.vue' /* webpackChunkName: "administrator-requirements-index" */),
				meta: {
					restricted: true,
					permission: 'home-index',
				},
			},
			// REQUERIMIENTOS - CREAR (ADMINISTRADOR GENERAL)
			{
				path: '/general_administrator/requirements/create',
				name: 'general_administrator-requirements-create',
				component: () => import('@/pages/general_administrator/requirements/create/Create.vue' /* webpackChunkName: "general_administrator-requirements-create" */),
				meta: {
					restricted: true,
					permission: 'home-index',
				},
			},
			// REQUERIMIENTOS - INDEXAR (ADMINISTRADOR GENERAL)
			{
				path: '/general_administrator/requirements/index',
				name: 'general_administrator-requirements-index',
				component: () => import('@/pages/general_administrator/requirements/index/Index.vue' /* webpackChunkName: "general_administrator-requirements-index" */),
				meta: {
					restricted: true,
					permission: 'home-index',
				},
			},

			// POSTULANTES PARA EXAMEN MEDICO (MEDICO)
			{
				path: '/doctor/postulants/index',
				name: 'doctor-postulants-index',
				component: () => import('@/pages/doctor/postulants/Postulants.vue' /* webpackChunkName: "doctor-postulants-index" */),
				meta: {
					restricted: true,
					permission: 'home-index',
				},
			},
			// POSTULANTES PARA FIRMA DE CONTRATO (RESPONSABLE DE FIRMA DE CONTRATO)
			{
				path: '/signing-contract-responsable/postulants/index',
				name: 'signing-contract-responsable-index',
				component: () => import('@/pages/contract_responsable/postulants/Postulants.vue' /* webpackChunkName: "contract-responsable-postulants-index" */),
				meta: {
					restricted: true,
					permission: 'home-index',
				},
			},
			{
				path: '/reports/requirements',
				name: 'reports-requirements',
				component: () => import('../pages/reports/requirements' /* webpackChunkName: "reportsRequirements" */),
				meta: {
					restricted: true,
					permission: 'maintenances-index',
				},
			},
			{
				path: '/change-password',
				name: 'change-password',
				component: () => import('../pages/change-password' /* webpackChunkName: "change-password" */),
				meta: {
					restricted: true,
					permission: 'home-index',
				},
			},
		],
	},
];

export default routes;
