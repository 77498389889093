<template>
	<div class="inner-main-header">
		<h5 class="mr-3">{{ propModel.alias }}</h5>
		<!-- <button v-if="cpd_can.includes(mtd_formatPermission('store')) && propButtons.create" @click="mtd_emitActionCreate" class="btn btn-primary btn-sm"> -->
		<button v-if="propButtons.create" @click="mtd_emitActionCreate" class="btn btn-primary btn-sm"><i class="ri-add-line align-middle me-2"></i> Nuevo</button>
		<slot name="button-extra"></slot>
		<div class="ml-auto">
			<button v-if="propButtons.back" class="btn btn-primary btn-sm ml-auto">Volver <i class="fas fa-chevron-right"></i></button>
			<ol class="breadcrumbs" style="margin-right: 1.5rem">
				<li>
					<a href="javascript:void(0)"><i :class="propModel.icon"></i></a>
				</li>
				<li class="breadcrumb-item" v-for="(item, index) in propConfig.menu" :key="index">
					<a href="javascript:void(0)">{{ item }}</a>
				</li>
			</ol>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		propConfig: {
			required: true,
		},
		propModel: {
			required: true,
		},
		propButtons: {
			required: true,
		},
	},
	computed: {
		cpd_can() {
			return this.$store.getters['can'];
		},
	},
	methods: {
		mtd_emitActionCreate: function () {
			this.$emit('action-create');
		},
		mtd_formatPermission: function (http) {
			return `${this.propModel.prefixPermission}-${http}`;
		},
	},
};
</script>
