<template>
	<div class="modal fade" id="component-modal-1" role="dialog" aria-labelledby="exampleModalLabel" data-backdrop="static" data-keyboard="false" aria-hidden="true">
		<div
			class="modal-dialog animate__animated animate__faster"
			role="document"
			:class="[propConfig.scroll ? 'modal-dialog-scrollable' : '', propConfig.size, propConfig.vertically, propConfig.position, classForPosition]">
			<div class="modal-content" style="max-height: 100%">
				<div class="modal-header">
					<h5 class="modal-title text-primary" id="exampleModalLabel" v-html="propConfig.title"></h5>
					<button type="button" class="close" @click="mtd_close">
						<span aria-hidden="true">
							<i v-if="propConfig.position == 'modal-left'" class="fas fa-arrow-alt-circle-left"></i>
							<i v-else-if="propConfig.position == 'modal-right'" class="fas fa-arrow-alt-circle-right"></i>
							<i v-else class="far fa-times-circle"></i>
						</span>
					</button>
				</div>
				<slot name="content-modal"></slot>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ['propConfig', 'propModel'],
	computed: {
		classForPosition() {
			return {
				animate__slideInLeft: this.propConfig.position == 'modal-left',
				animate__slideInRight: this.propConfig.position == 'modal-right',
			};
		},
	},
	methods: {
		mtd_close: function () {
			const firstChildrenModal = window.$(`#${this.propConfig.id}`).children().first();
			switch (this.propConfig.position) {
				case 'modal-left':
					firstChildrenModal.toggleClass('animate__slideOutLeft');
					break;
				case 'modal-right':
					firstChildrenModal.toggleClass('animate__slideOutRight');
					break;
			}

			setTimeout(() => {
				// window.$(`#${this.propConfig.id}`).modal("hide");
				this.$emit('action-close');
				switch (this.propConfig.position) {
					case 'modal-left':
						firstChildrenModal.toggleClass('animate__slideOutLeft');
						break;
					case 'modal-right':
						firstChildrenModal.toggleClass('animate__slideOutRight');
						break;
				}
			}, 380);
		},
	},
};
</script>
<style scoped>
.modal-left {
	margin-left: 0;
	margin-top: 0;
	margin-bottom: 0;
	min-height: 100%;
}
.modal-right {
	margin-right: 0;
	margin-top: 0;
	margin-bottom: 0;
	min-height: 100%;
}
</style>
