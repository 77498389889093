/**
 * !Import vue and vuex
 */
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
/**
 * !Import modules
 */
import global_vars from './modules/global_vars/index';
import authentication from './modules/authentication/state';
import enterprises from './modules/enterprises/index';
import job_positions from './modules/job_positions/index';
import users from './modules/users/index';

//REQUIREMENT RESPONSABLE //TODO: REVISAR SOBRE MODULOS DENTRO DE MUDLOS PARA MEJORAR LA ARQUITECTURA DEL STORE
import requirement_responsable_requirements from '@/store/modules/requirement_responsable/requirements/index';
//ADMINISTRATOR
import administrator_requirements from '@/store/modules/administrator/requirements/index';
//GENERAL ADMINISTRATOR
import general_administrator_requirements from '@/store/modules/general_administrator/requirements/index';
//SELECTION ADMINISTRATOR
import selection_administrator_requirements from '@/store/modules/selection_administrator/requirements/index';
//POSTULANT
import postulant_requirements from '@/store/modules/postulant/requirements/index';
//DOCTOR
import doctor_postulants from '@/store/modules/doctor/postulants/index';
//REQUIREMENT STATE
import requirements_state from '@/store/modules/requirements/state/index';
//contract_responsable
import contract_responsable from '@/store/modules/contract_responsable/postulants/index';
// campus
import campus from '@/store/modules/campus/index';
//benefit_types
import benefit_types from '@/store/modules/benefit_types/index';
//sources
import sources from '@/store/modules/sources/index';
/**
 * !Import Actions
 */
import * as actions from './actions';

Vue.use(Vuex);

export default new Vuex.Store({
	actions,
	modules: {
		global_vars,
		authentication,
		enterprises,
		job_positions,
		users,
		//ADMINISTRATOR
		administrator_requirements,
		//GENERAL ADMINISTRATOR
		general_administrator_requirements,
		//REQUIREMENT RESPONSABLE
		requirement_responsable_requirements,
		//SELECTION ADMINISTRATOR
		selection_administrator_requirements,
		campus,
		benefit_types,
		sources,
		//POSTULANT
		postulant_requirements,
		//DOCTOR
		doctor_postulants,
		//RESPONSABLE DE CONTRATO
		contract_responsable,
		//SHARED
		requirements_state,
	},
	plugins: [
		createPersistedState({
			paths: ['authentication'],
		}),
	],
});
