import { REQUIREMENT__SET_ALL, STEPS__SET_ALL, IS_EDITABLE__UPDATE } from './types';

export default {
	[REQUIREMENT__SET_ALL]: (state, payload) => {
		state.requirement = payload;
	},
	[STEPS__SET_ALL]: (state, payload) => {
		state.steps = payload.map(el => ({
			...el,
			current: el.requirement_steps.find(el => el.current == 1) ? 1 : 0,
		}));

		const publication = state.steps.find(el => el.step_code == 'PUBLICATION');
		if (publication) state.publication = publication;

		const curriculum_filter = state.steps.find(el => el.step_code == 'CURRICULUM_FILTER');
		if (curriculum_filter) state.curriculum_filter = curriculum_filter;

		const evaluation = state.steps.find(el => el.step_code == 'EVALUATION');
		if (evaluation) {
			state.evaluation = evaluation;

			state.evaluation.requirement_steps = state.evaluation.requirement_steps.map(el => ({
				...el,
				steps: el.steps.map(el => ({
					...el,
					current: el.requirement_steps.find(el => el.current == 1) ? 1 : 0,
				})),
			}));
		}

		const previous_interview = state.steps.find(el => el.step_code == 'PREVIOUS_INTERVIEW');
		if (previous_interview) {
			state.previous_interview = previous_interview;
		}

		const principal_interview = state.steps.find(el => el.step_code == 'PRINCIPAL_INTERVIEW');
		if (principal_interview) {
			state.principal_interview = principal_interview;
		}

		const medical_examen = state.steps.find(el => el.step_code == 'MEDICAL_EXAM');
		if (medical_examen) {
			state.medical_examen = medical_examen;
		}

		const closing = state.steps.find(el => el.step_code == 'CLOSING');
		if (closing) {
			state.closing = closing;
		}

		const finished = state.steps.find(el => el.step_code == 'FINISHED');
		if (finished) {
			state.finished = finished;
		}
	},
	[IS_EDITABLE__UPDATE]: (state, payload) => {
		state.isEditable = payload;
	},
};
