/**
 * API
 * !backend: Laravel
 */
export default {
	get(request, success, danger) {
		const config = {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
				ContentType: 'application/json',
			},
		};

		window.axios
			.get(request.url, config)
			.then(
				res => success(res),
				err => {
					window.Vue.default.httpCode.accordingStatus(err.response);
					danger(err.response);
				}
			)
			.catch(error => console.log(`Error-get en api.js ${error}`));
	},

	post(request, success, danger) {
		const config = {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
				ContentType: 'application/json',
			},
		};
		window.axios
			.post(request.url, request.params, config)
			.then(
				res => success(res),
				err => {
					window.Vue.default.httpCode.accordingStatus(err.response);
					danger(err.response);
				}
			)
			.catch(error => console.log(`Error-post en api.js ${error}`));
	},

	update(request, success, danger) {
		const config = {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
				ContentType: 'application/json',
			},
		};
		window.axios
			.patch(request.url, request.params, config)
			.then(
				res => success(res),
				err => {
					window.Vue.default.httpCode.accordingStatus(err.response);
					danger(err.response);
				}
			)
			.catch(error => console.log(`Error-update en api.js ${error}`));
	},

	destroy(request, success, danger) {
		const config = {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
				ContentType: 'application/json',
			},
		};
		window.axios
			.delete(request.url, config)
			.then(
				res => success(res),
				err => {
					window.Vue.default.httpCode.accordingStatus(err.response);
					danger(err.response);
				}
			)
			.catch(error => console.log(`Error-destroy en api.js ${error}`));
	},

	postWithFile(request, success, danger) {
		const config = {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
				ContentType: 'application/json',
			},
		};
		window.axios
			.post(request.url, request.formData, config)
			.then(
				res => success(res),
				err => {
					window.Vue.default.httpCode.accordingStatus(err.response);
					danger(err.response);
				}
			)
			.catch(error => console.log(`Error-post-with-file en api.js ${error}`));
	},
};
