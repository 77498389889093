const state = {
	requirements_index: {
		data: [
			{
				code: '11',
			},
		],
		links: {},
		meta: {},
	},
};

export default state;
