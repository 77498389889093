<template>
	<div>
		<template v-if="errorsBackend[name]">
			<span v-for="(error, index) in errorsBackend[name]" class="form-group__error" :key="index"> <i class="fas fa-exclamation-circle"></i> {{ error }} </span>
		</template>
	</div>
</template>
<script>
export default {
	props: {
		errorsBackend: {
			required: true,
		},
		name: {
			required: true,
		},
	},
};
</script>
<style></style>
