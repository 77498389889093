<template>
	<render-pagination :links="links" :meta="meta" v-on:pagination-change-page="onPaginationChangePage">
		<ul class="pagination pagination-sm pagination-circle mb-0 ml-sm-auto mt-1 mt-2" slot-scope="{ meta, links, computed, prevButtonEvents, nextButtonEvents, pageButtonEvents }">
			<li class="page-item" :class="{ disabled: !computed.prevPageUrl }">
				<a class="page-link" href="#" v-on="prevButtonEvents"><feather type="chevron-left"></feather></a>
			</li>
			<li
				class="page-item"
				v-for="(page, key) in computed.pageRange"
				:key="key"
				:class="{
					active: page == computed.currentPage,
					disabled: page == '...',
				}">
				<a class="page-link" v-if="page == computed.currentPage">{{ page }}</a>
				<a class="page-link" href="#" v-on="pageButtonEvents(page)" v-else>{{ page }}</a>
			</li>
			<li class="page-item" :class="{ disabled: !computed.nextPageUrl }">
				<a class="page-link" href="#" v-on="nextButtonEvents"><feather type="chevron-right"></feather></a>
			</li>
		</ul>
	</render-pagination>
</template>
<script>
import RenderPagination from './RenderPagination.vue';
export default {
	props: {
		links: {
			type: Object,
			default: () => {},
		},
		meta: {
			type: Object,
			default: () => {},
		},
	},
	components: {
		RenderPagination,
	},
	methods: {
		onPaginationChangePage(page) {
			this.$emit('pagination-change-page', page);
		},
	},
};
</script>
