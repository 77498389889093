import {
	ENTERPRISES_INDEX__SET_ALL,
	ENTERPRISES_INDEX__ADD_RESOURCE,
	ENTERPRISES_INDEX__UPDATE_RESOURCE,
	ENTERPRISES_INDEX__REMOVE_RESOURCE,
	ENTERPRISES_INDEX__CLEAR_ALL,
} from './types';

export default {
	[ENTERPRISES_INDEX__SET_ALL]: (state, payload) => {
		state.enterprises_index = payload;
	},
	[ENTERPRISES_INDEX__ADD_RESOURCE]: (state, { enterprise }) => {
		state.enterprises_index.data.push(enterprise);
	},
	[ENTERPRISES_INDEX__UPDATE_RESOURCE]: (state, { enterprise }) => {
		state.enterprises_index.data = state.enterprises_index.data.map(el => {
			if (el.id == enterprise.id) el = enterprise;
			return el;
		});
	},
	[ENTERPRISES_INDEX__REMOVE_RESOURCE]: (state, { enterprise }) => {
		state.enterprises_index.data = state.enterprises_index.data.filter(el => el.id != enterprise.id);
	},
	[ENTERPRISES_INDEX__CLEAR_ALL]: state => {
		state.enterprises_index = {
			...{
				data: [],
				links: {},
				meta: {},
			},
		};
	},
};
