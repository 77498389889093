import {
	JOB_POSITIONS_INDEX__SET_ALL,
	JOB_POSITIONS_INDEX__ADD_RESOURCE,
	JOB_POSITIONS_INDEX__UPDATE_RESOURCE,
	JOB_POSITIONS_INDEX__REMOVE_RESOURCE,
	JOB_POSITIONS_INDEX__CLEAR_ALL,
} from './types';

export default {
	[JOB_POSITIONS_INDEX__SET_ALL]: (state, payload) => {
		state.job_positions_index = payload;
	},
	[JOB_POSITIONS_INDEX__ADD_RESOURCE]: (state, { job_position }) => {
		if (job_position.type == '1') {
			state.job_positions_index.data.push(job_position);
		}
	},
	[JOB_POSITIONS_INDEX__UPDATE_RESOURCE]: (state, { job_position }) => {
		state.job_positions_index.data = state.job_positions_index.data.map(el => {
			if (el.id == job_position.id) el = job_position;
			return el;
		});
	},
	[JOB_POSITIONS_INDEX__REMOVE_RESOURCE]: (state, { job_position }) => {
		state.job_positions_index.data = state.job_positions_index.data.filter(el => el.id != job_position.id);
	},
	[JOB_POSITIONS_INDEX__CLEAR_ALL]: state => {
		state.job_positions_index = {
			...{
				data: [],
				links: {},
				meta: {},
			},
		};
	},
};
